import { ref } from 'vue';

export default () => {
    const selected = ref('');
    const handleSelected = (newValue) => {
        if (newValue !== selected.value) {
            selected.value = newValue;
        }
    };

    return {
        selected,
        handleSelected
    };
};
