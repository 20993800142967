import { ref } from 'vue';

export default () => {
    const selected = ref([]);
    const handleSelected = (newValue) => {
        if (Array.isArray(newValue)) {
            selected.value = [...newValue];
        } else {
            if (selected.value.includes(newValue)) {
                selected.value = selected.value.filter(val => val !== newValue);
            } else {
                selected.value.push(newValue);
            }
        }
    };

    return {
        selected,
        handleSelected
    };
};
