<template>
  <div
    ref="target"
    :class="variant"
  >
    <slot name="label">
      <label
        v-if="label"
        id="listbox-label"
        :class="labelClass"
      >
        {{ label }}
        <span
          v-if="isRequired"
          class="text-primary-red"
        >*</span>
      </label>
    </slot>
    <div
      :class="dropdownBtnContainerClass"
    >
      <base-button
        v-if="showDropdownButton && (disableTypeAhead || !toggleDropdown)"
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="dropdown-button"
        size="p-0"
        :class="[
          error ? 'error' : '',
          textAlign
        ]"
        :disabled="disabled"
        v-bind="btnProps"
        @click.stop="handleDropdown()"
      >
        <template #default>
          <!-- scoped slot for selected option -->
          <slot
            name="selectedOption"
            v-bind="{placeholder, anyOptionSelected, selected, multiSelect}"
          >
            <span
              v-if="placeholder && !anyOptionSelected"
              class="text-primary-gray"
              :class="textContainerClass"
            >
              {{ textValueOverride || placeholder }}
            </span>
            <template v-else>
              <span
                v-if="multiSelect"
                :class="textContainerClass"
              >
                {{ textValueOverride || `${selected.length} selected` }}
              </span>
              <span
                v-else
                :class="textContainerClass"
              >
                {{ textValueOverride || selectedText }}
              </span>
            </template>
          </slot>
          <div
            v-if="error"
            class="mr-1"
          >
            <slot name="errorIcon">
              <base-svg
                src="icons/errorCircle.svg"
                class="text-red-600 h-4 w-4"
                :svg-attributes="{
                  class: 'w-full h-full'
                }"
                tag="div"
              />
            </slot>
          </div>
          <slot name="dropdownIcon">
            <base-svg
              src="icons/arrowDownWide.svg"
              class="dropdown-icon"
              :svg-attributes="{
                class: 'w-3 h-4'
              }"
              tag="span"
            />
          </slot>
        </template>
      </base-button>
      <div
        v-show="toggleDropdown && !disableTypeAhead"
        class="flex"
      >
        <base-input
          ref="typeAheadInputRef"
          :model-value="typeAheadKeyword"
          input-inner-container-class="focus:outline-none focus:border-blue-600 rounded-sm border border-custom-gray-8 text-custom-gray-7 flex items-center"
          container-class="w-full"
          :placeholder="selectedText || placeholder"
          @update:modelValue="handleInputValueChange"
        />
        <div
          class="w-7 border border-l-0 border-custom-gray-8 flex justify-center items-center"
          @click.stop="handleDropdown()"
        >
          <slot name="dropdownIcon">
            <base-svg
              src="icons/arrowDownWide.svg"
              class="dropdown-icon"
              :svg-attributes="{
                class: 'w-3 h-4'
              }"
              tag="span"
            />
          </slot>
        </div>
      </div>
      <transition
        name="Dropdown-transition"
        enter-from-class=""
        enter-active-class=""
        enter-to-class=""
        leave-from-class="opacity-100"
        leave-active-class="transition ease-in duration-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="toggleDropdown"
          class="absolute w-full bg-white z-50 w-auto"
          :class="dropdownContainerClass"
          style="box-shadow: 0px 18px 20px 10px #00000064;"
        >
          <ul
            ref="dropdownRef"
            tabindex="0"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-item-3"
            class="focus:outline-none overflow-auto sm:text-sm dropdown"
            :class="dropdownClass"
          >
            <div
              v-if="__theOptions.length === 0"
              class="p-4 text-center"
            >
              <base-svg
                class="h-4 w-4 mr-1 text-primary-red inline-block"
                src="icons/circleSpinner.svg"
                tag="span"
              />
            </div>

            <li
              v-for="(option, oIndex) in (showDefaultOption ? [defaultOption, ...__theOptions] : __theOptions)"
              v-else
              :id="'listbox-item-' + oIndex"
              :key="'option' + oIndex"
              role="option"
              class="list-item"
              :class="[
                isOptionSelected(option) && !(oIndex === 0 && showDefaultOption) ? 'selected-item ' + selectedItemClass : '',
                listItemClass
              ]"
              @click="selectOption(option)"
            >
              <div class="flex items-center">
                <span
                  v-if="oIndex === 0 && showDefaultOption"
                  class="block"
                >
                  {{ defaultOptionText }}
                </span>
                <span
                  v-else
                  class="block"
                >
                  {{ isOptionItemObject ? option[itemTextProperty] : option }}
                </span>
              </div>

              <!-- <template v-if="showSelectedOptionIcon">
                <slot
                  name="selectedOptionIcon"
                  v-bind="{ option, isOptionSelected }"
                >
                  <base-svg
                    v-show="isOptionSelected(option) && !(oIndex === 0 && showDefaultOption)"
                    src="icons/check.svg"
                    class="absolute inset-y-0 right-0 flex items-center pr-4"
                    tag="span"
                    :svg-attributes="{
                      class: 'h-5 w-5'
                    }"
                  />
                </slot>
              </template> -->
            </li>
          </ul>
          <div class="my-2" />
        </div>
      </transition>
      <small
        v-if="error && errorText === ''"
        class="block mb-2 text-red-600"
      >
        {{ error }}
      </small>
      <small
        v-if="errorText !== '' && error"
        class="block mb-2 text-red-600"
      >
        {{ errorText }}
      </small>
    </div>
  </div>
</template>

<script>
import useSingleSelect from '@/hooks/singleSelect.js';
import useMultiSelect from '@/hooks/multiSelect.js';
import useToggle from '@/hooks/toggle.js';
import { computed, defineAsyncComponent, onMounted, ref, watch, nextTick } from 'vue';
import BaseInput from './BaseInput.vue';
import { onClickOutside } from '@vueuse/core';

export default {
    name: 'BaseSelect',

    components: {
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BaseInput
    },

    props: {
        options: {
            type: Array,
            default: () => [],
            description: 'options for selecting'
        },
        modelValue: {
            type: [String, Number, Array],
            required: true,
            description: 'bound value'
        },
        multiSelect: {
            type: Boolean,
            default: false,
            description: 'allows to select multiple options'
        },
        label: {
            type: String,
            default: '',
            description: 'label for select(to be used when label not provided from slot)'
        },
        labelClass: {
            type: String,
            default: 'label',
            description: 'css classes applied to label'
        },
        error: {
            type: String,
            default: '',
            description: 'error message for switching layout of input to error layout'
        },
        errorText: {
            type: String,
            default: '',
            description: 'error message to show under required field'
        },
        placeholder: {
            type: String,
            default: '',
            description: 'placeholder for select'
        },
        btnProps: {
            type: Object,
            default: () => {},
            description: 'props applied to dropdown button'
        },
        dropdownBtnContainerClass: {
            type: [Object, String, Array],
            default: 'relative w-full text-left min-w-0',
            description: 'classes applied to container of dropdown button'
        },
        dropdownContainerClass: {
            type: [Object, String, Array],
            default: 'shadow-lg'
        },
        dropdownClass: {
            type: [Object, String, Array],
            default: 'max-h-56 rounded-md py-1',
            description: 'classes applied to dropdown menu'
        },
        listItemClass: {
            type: String,
            default: 'text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-indigo-600',
            description: 'classes applied to each item in list'
        },
        selectedItemClass: {
            type: String,
            default: 'text-white bg-indigo-600',
            description: 'classes applied to selected item in list'
        },
        showSelectedOptionIcon: {
            type: Boolean,
            default: true,
            description: 'toggles icon on selected option(hidden by default)'
        },
        showDropdownButton: {
            type: Boolean,
            default: true,
            description: 'toggles dropdown button(shown by default)'
        },
        disableSelection: {
            type: Boolean,
            default: false,
            description: 'disables selecting values from dropdown'
        },
        itemTextProperty: {
            type: String,
            default: '',
            description: 'property for dropdown options text(required when each item in options array is object)'
        },
        itemValueProperty: {
            type: String,
            default: '',
            description: 'property for dropdown options value(required when each item in options array is object)'
        },
        textContainerClass: {
            type: String,
            default: 'w-full h-7 inline-block p-1 truncate',
            description: 'Classes applied to selected option text container'
        },
        textValueOverride: {
            type: String,
            default: '', // 'Choose Columns...',
            description: 'Some text to overrider the value displayed'
        },
        defaultOptionText: {
            type: String,
            default: '-- SELECT --',
            description: 'text shown for default option in options dropdown'
        },
        defaultOptionValue: {
            type: [String, Number, Object],
            default: '',
            description: 'value to be returned to bound variable when default option is selected (note: Object type for null only)'
        },
        showDefaultOption: {
            type: Boolean,
            default: false,
            description: 'show/hide default option in dropdown'
        },
        ignoreSelection: {
            type: Boolean,
            default: false,
            description: 'ignore the selection of the value in dropdown'
        },
        disableTypeAhead: {
            type: Boolean,
            default: false,
            description: 'disables type ahead feature if set to true'
        },
        variant: {
            type: String,
            default: 'select-default',
            description: 'group of css classes applied to style whole component (check variants in style section)'
        },
        isRequired: {
            type: Boolean,
            default: false,
            description: 'adds asterisk to label to denote required field'
        },
        disabled: {
            type: Boolean,
            default: false,
            description: 'disables component if set to true'
        },
        disabledClass: {
            type: String,
            default: 'disabled',
            description: 'css classes applied on component in disabled state'
        },
        textAlign: {
            type: String,
            default: 'text-left',
            description: 'css class for text alignment on dropdown button'
        }
    },

    emits: [
        'update:modelValue',
        'dropdownOpened',
        'dropdownClosed'
    ],

    setup (props, { emit }) {
        // option select logic
        const { selected, handleSelected } = props.multiSelect ? useMultiSelect() : useSingleSelect();
        onMounted(() => {
            handleSelected(props.modelValue);
        });

        watch(
            () => props.modelValue,
            () => {
                handleSelected(props.modelValue);
            },
            { deep: true }
        );
        const isOptionItemObject = computed(() => props.options?.length ? props.options[0] && !['string', 'number', 'boolean'].includes(typeof props.options[0]) : false);
        const selectedText = computed(() => {
            if (isOptionItemObject.value && !props.multiSelect) {
                const matchedOption = props.options.find(option => option && option[props.itemValueProperty] === selected.value);
                return matchedOption ? matchedOption[props.itemTextProperty] : selected.value;
            }
            return selected.value;
        });
        const getOptionValue = (option) => {
            return isOptionItemObject.value ? option[props.itemValueProperty] : option;
        };
        const defaultOption = computed(() => isOptionItemObject.value ? { [props.itemTextProperty]: props.defaultOptionText, [props.itemValueProperty]: props.defaultOptionValue } : props.defaultOptionValue);
        const isOptionSelected = (option) => {
            const selectedOption = getOptionValue(option);
            return props.multiSelect ? selected.value.includes(selectedOption) : selected.value === selectedOption;
        };
        const anyOptionSelected = computed(() => {
            if (props.multiSelect) {
                return selected.value && selected.value.length;
            }
            return selected.value;
        });
        const selectOption = (option) => {
            if (props.disableSelection && !isOptionSelected(option)) {
                return;
            }
            let shouldToggleDropdown = !props.multiSelect;

            if (!props.ignoreSelection) {
                if (getOptionValue(option) === getOptionValue(defaultOption.value)) {
                    // if default option is selected, clear selected value
                    selected.value = props.multiSelect ? [] : props.defaultOptionValue;
                    shouldToggleDropdown = true;
                } else {
                    // otherwise, handle selection
                    handleSelected(getOptionValue(option));
                }
                emit('update:modelValue', selected.value);
            } else emit('update:modelValue', getOptionValue(option));

            if (shouldToggleDropdown) {
                handleDropdownToggle();
            }
            if (typeAheadKeyword.value) {
                typeAheadKeyword.value = '';
            }
        };

        // Dropdown toggle logic
        const { toggle: toggleDropdown, handleToggle: handleDropdownToggle } = useToggle();

        /* v-click-outside fix. */
        const target = ref(null);
        onClickOutside(target, (event) => {
            toggleDropdown.value = false;
        });

        // const hideDropdown = () => {
        //     toggleDropdown.value = false;
        // };
        /* v-click-outside fix. */

        const handleDropdown = async () => {

            handleDropdownToggle();
            if (toggleDropdown.value) {
                await emit('dropdownOpened');
                const scrollTarget = document.getElementsByClassName('selected-item')[0]
                if (scrollTarget) {
                    scrollTarget.scrollIntoView({ block: 'center' });
                }
            } else {
                emit('dropdownClosed');
            }
        };

        // type ahead logic
        const typeAheadKeyword = ref('');
        const dropdownRef = ref(null); // ref for dropdown

        const __theOptions = computed(() => {
            if (typeAheadKeyword.value.length >= 2) {
                return props.options.filter(option => getOptionValue(option)?.toLowerCase().includes(typeAheadKeyword.value.toLowerCase())).length > 0 ? props.options.filter(option => getOptionValue(option)?.toLowerCase().includes(typeAheadKeyword.value.toLowerCase())) : [''];
            } else {
                return props.options;
            }
        });

        // const optionValues = computed(() => props.options.map(option => getOptionValue(option)?.toLowerCase()));
        const handleInputValueChange = (inputValue) => {
            // console.log(`>> handleInputValueChange`)
            typeAheadKeyword.value = inputValue;
        };

        const typeAheadInputRef = ref(null);
        const handleTypeAheadFocus = async () => {
            await nextTick();
            typeAheadInputRef.value.$el.querySelector('input').focus();
        };
        watch(
            () => toggleDropdown.value,
            () => {
                if (toggleDropdown.value) {
                    handleTypeAheadFocus();
                } else {
                    typeAheadKeyword.value = '';
                }
            }
        );

        return {
            selected,
            handleSelected,
            isOptionSelected,
            anyOptionSelected,
            isOptionItemObject,
            getOptionValue,
            defaultOption,
            selectedText,
            selectOption,
            toggleDropdown,
            handleDropdownToggle,
            handleDropdown,
            // type ahead
            dropdownRef,
            typeAheadKeyword,
            handleInputValueChange,
            typeAheadInputRef,
            handleTypeAheadFocus,
            __theOptions,
            target
        };
    }
};
</script>

<style scoped>
@layer components {
  /* select variants */
  .select-default {
    .dropdown {
      @apply max-h-56 rounded-sm;
    }
    .dropdown-button {
      @apply focus:outline-none rounded-sm border border-custom-gray-8 text-custom-gray-7 flex items-center w-full;
    }
    .list-item {
      @apply cursor-default select-none relative text-black py-1 pl-3 pr-9 hover:bg-custom-gray-2;
    }
    .selected-item {
      @apply bg-custom-gray-2 font-semibold;
    }
    .dropdown-icon {
      @apply float-right h-7 w-7 text-white font-bold bg-primary-red flex justify-center items-center border border-primary-red rounded-r-sm;
    }
  }
  .select-outline {
    @apply select-default;
    .dropdown-icon {
      @apply float-right h-7 w-7 text-black font-bold flex justify-center items-center bg-transparent border-none;
    }
  }
  .select-filled-red {
    @apply select-default;
    .dropdown-button {
      @apply focus:outline-none rounded-sm border-none bg-primary-red text-white flex items-center w-full;
    }
  }

  /* select state styling */
  .dropdown-button {
      &:disabled {
          @apply bg-custom-gray-6 cursor-not-allowed opacity-70;
      }
  }
  .error {
      @apply border border-red-600;
  }

  /* label variants */
  .label {
    @apply block text-sm text-black mr-3.5 flex-shrink-0;
  }
  .label-w-46 {
    @apply block text-sm text-black mr-3.5 w-46 flex-shrink-0;
  }
  .label-w-36 {
      @apply block text-sm text-black mr-3.5 w-36 flex-shrink-0;
  }
}
</style>
